#error-snackbar {
  background: #e53e3e;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  z-index: 9999999;
}

#copy-snackbar {
  background: #75934e;
  width: 100%;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  z-index: 9999999;
}

.img {
  position: relative;
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: text-bottom;
}

.img::before {
  content: '\00d7';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  line-height: 25px;
  background-color: #f00000;
  font-size: 25px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  display: block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  font-weight: bolder;
}

header.md-paper--1 {
  display: none !important;
}

#main-content {
  margin-top: 0 !important;
}

#main-content > div.md-grid {
  padding: 0 8px !important;
}

#main-content div.md-toolbar-relative {
  margin-top: 64px !important;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

.md-dialog-container.md-overlay,
.md-dialog--full-page {
  z-index: 999999999;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
  font-family: Rubik;
  padding: 0 20px;
  border: 1px solid #eee;
  font-size: 12px;
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.47) !important;
  font-weight: 700 !important;
}

html {
  background-color: rgba(156, 178, 211, 0.1);
}
